/*!
*
*  - Responsive Bootstrap 5 Admin Dashboard Template
* Author: iqonic.design
* Design and Developed by: ahmad.design
* NOTE: This file contains the styling for color variable.
*#7367F0
*/
a {
  color: var(--bs-primary);
}

a:hover {
  color: var(--bs-primary);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: #232d42;
}

body {
  background-color: #f2edf3;
}

.bg-gradient-danger {
  background: linear-gradient(to right, #ffbf96, #fe7096) !important;
}

:root {
  --bs-heading-color: #2b1014;
  --bs-primary: #d94f66;
  --bs-secondary: #734761;
  --bs-primary-shade-80: #2b1014;
  --bs-primary-shade-60: #dd6175;
  --bs-primary-shade-40: #e17285;
  --bs-primary-shade-20: #e48494;
  --bs-primary-tint-80: #d8ddfa;
  --bs-primary-tint-60: #b0bcf6;
  --bs-primary-tint-40: #899af1;
  --bs-primary-tint-20: #6179ed;
  --bs-primary-rgb: 58, 87, 232;
  --bs-primary-tint-88: #e7ebfc;
  --bg-bg-light1: #f2edf3;
  --card-bg: #f5f5f9;
  --btn-danger1: #ff3e1d;
  --btn-shadow: 0 0.5rem 1.125rem -0.425rem rgb(254 105 106 / 90%);
}

:root .theme-color-blue {
  --bs-heading-color: #0c112e;
  --bs-blue: #d94f66;
  --bs-blue-shade-80: #0c112e;
  --bs-blue-shade-60: #17235d;
  --bs-blue-shade-40: #23348b;
  --bs-blue-shade-20: #2e46ba;
  --bs-blue-tint-80: #d8ddfa;
  --bs-blue-tint-60: #b0bcf6;
  --bs-blue-tint-40: #899af1;
  --bs-blue-tint-20: #6179ed;
  --bs-blue-rgb: 58, 87, 232;
  --bs-blue-tint-88: #e7ebfc;
  --bs-heading-color: #140330;
  --bs-indigo: #6610f2;
  --bs-indigo-shade-80: #140330;
  --bs-indigo-shade-60: #290661;
  --bs-indigo-shade-40: #3d0a91;
  --bs-indigo-shade-20: #520dc2;
  --bs-indigo-tint-80: #e0cffc;
  --bs-indigo-tint-60: #c29ffa;
  --bs-indigo-tint-40: #a370f7;
  --bs-indigo-tint-20: #8540f5;
  --bs-indigo-rgb: 102, 16, 242;
  --bs-indigo-tint-88: #ede2fd;
  --bs-heading-color: #160d27;
  --bs-purple: #6f42c1;
  --bs-purple-shade-80: #160d27;
  --bs-purple-shade-60: #2c1a4d;
  --bs-purple-shade-40: #432874;
  --bs-purple-shade-20: #59359a;
  --bs-purple-tint-80: #e2d9f3;
  --bs-purple-tint-60: #c5b3e6;
  --bs-purple-tint-40: #a98eda;
  --bs-purple-tint-20: #8c68cd;
  --bs-purple-rgb: 111, 66, 193;
  --bs-purple-tint-88: #eee8f8;
  --bs-heading-color: #2b0a1a;
  --bs-pink: #d63384;
  --bs-pink-shade-80: #2b0a1a;
  --bs-pink-shade-60: #561435;
  --bs-pink-shade-40: #801f4f;
  --bs-pink-shade-20: #ab296a;
  --bs-pink-tint-80: #f7d6e6;
  --bs-pink-tint-60: #efadce;
  --bs-pink-tint-40: #e685b5;
  --bs-pink-tint-20: #de5c9d;
  --bs-pink-rgb: 214, 51, 132;
  --bs-pink-tint-88: #fae7f0;
  --bs-heading-color: #260a07;
  --bs-red: #c03221;
  --bs-red-shade-80: #260a07;
  --bs-red-shade-60: #4d140d;
  --bs-red-shade-40: #731e14;
  --bs-red-shade-20: #9a281a;
  --bs-red-tint-80: #f2d6d3;
  --bs-red-tint-60: #e6ada6;
  --bs-red-tint-40: #d9847a;
  --bs-red-tint-20: #cd5b4d;
  --bs-red-rgb: 192, 50, 33;
  --bs-red-tint-88: #f7e6e4;
  --bs-heading-color: #32220b;
  --bs-orange: #faa938;
  --bs-orange-shade-80: #32220b;
  --bs-orange-shade-60: #644416;
  --bs-orange-shade-40: #966522;
  --bs-orange-shade-20: #c8872d;
  --bs-orange-tint-80: #feeed7;
  --bs-orange-tint-60: #fdddaf;
  --bs-orange-tint-40: #fccb88;
  --bs-orange-tint-20: #fbba60;
  --bs-orange-rgb: 250, 169, 56;
  --bs-orange-tint-88: #fef5e7;
  --bs-heading-color: #301505;
  --bs-yellow: #f16a1b;
  --bs-yellow-shade-80: #301505;
  --bs-yellow-shade-60: #602a0b;
  --bs-yellow-shade-40: #914010;
  --bs-yellow-shade-20: #c15516;
  --bs-yellow-tint-80: #fce1d1;
  --bs-yellow-tint-60: #f9c3a4;
  --bs-yellow-tint-40: #f7a676;
  --bs-yellow-tint-20: #f48849;
  --bs-yellow-rgb: 241, 106, 27;
  --bs-yellow-tint-88: #fdede4;
  --bs-heading-color: #052011;
  --bs-green: #1aa053;
  --bs-green-shade-80: #052011;
  --bs-green-shade-60: #0a4021;
  --bs-green-shade-40: #106032;
  --bs-green-shade-20: #158042;
  --bs-green-tint-80: #d1ecdd;
  --bs-green-tint-60: #a3d9ba;
  --bs-green-tint-40: #76c698;
  --bs-green-tint-20: #48b375;
  --bs-green-rgb: 26, 160, 83;
  --bs-green-tint-88: #e4f4ea;
  --bs-heading-color: #06281e;
  --bs-teal: #20c997;
  --bs-teal-shade-80: #06281e;
  --bs-teal-shade-60: #0d503c;
  --bs-teal-shade-40: #13795b;
  --bs-teal-shade-20: #1aa179;
  --bs-teal-tint-80: #d2f4ea;
  --bs-teal-tint-60: #a6e9d5;
  --bs-teal-tint-40: #79dfc1;
  --bs-teal-tint-20: #4dd4ac;
  --bs-teal-rgb: 32, 201, 151;
  --bs-teal-tint-88: #e4f9f3;
  --bs-heading-color: #011f20;
  --bs-cyan: #079aa2;
  --bs-cyan-shade-80: #011f20;
  --bs-cyan-shade-60: #033e41;
  --bs-cyan-shade-40: #045c61;
  --bs-cyan-shade-20: #067b82;
  --bs-cyan-tint-80: #cdebec;
  --bs-cyan-tint-60: #9cd7da;
  --bs-cyan-tint-40: #6ac2c7;
  --bs-cyan-tint-20: #39aeb5;
  --bs-cyan-rgb: 7, 154, 162;
  --bs-cyan-tint-88: #e1f3f4;
  --bs-heading-color: #333333;
  --bs-white: #fff;
  --bs-white-shade-80: #333333;
  --bs-white-shade-60: #666666;
  --bs-white-shade-40: #999999;
  --bs-white-shade-20: #cccccc;
  --bs-white-tint-80: white;
  --bs-white-tint-60: white;
  --bs-white-tint-40: white;
  --bs-white-tint-20: white;
  --bs-white-rgb: 255, 255, 255;
  --bs-white-tint-88: white;
  --bs-heading-color: #161719;
  --bs-gray: #6c757d;
  --bs-gray-shade-80: #161719;
  --bs-gray-shade-60: #2b2f32;
  --bs-gray-shade-40: #41464b;
  --bs-gray-shade-20: #565e64;
  --bs-gray-tint-80: #e2e3e5;
  --bs-gray-tint-60: #c4c8cb;
  --bs-gray-tint-40: #a7acb1;
  --bs-gray-tint-20: #899197;
  --bs-gray-rgb: 108, 117, 125;
  --bs-gray-tint-88: #edeeef;
  --bs-heading-color: #0a0c0d;
  --bs-gray-dark: #343a40;
  --bs-gray-dark-shade-80: #0a0c0d;
  --bs-gray-dark-shade-60: #15171a;
  --bs-gray-dark-shade-40: #1f2326;
  --bs-gray-dark-shade-20: #2a2e33;
  --bs-gray-dark-tint-80: #d6d8d9;
  --bs-gray-dark-tint-60: #aeb0b3;
  --bs-gray-dark-tint-40: #85898c;
  --bs-gray-dark-tint-20: #5d6166;
  --bs-gray-dark-rgb: 52, 58, 64;
  --bs-gray-dark-tint-88: #e7e7e8;
  --bs-heading-color: #002732;
  --bs-primary: #00c3f9;
  --bs-primary-shade-80: #002732;
  --bs-primary-shade-60: #004e64;
  --bs-primary-shade-40: #007595;
  --bs-primary-shade-20: #009cc7;
  --bs-primary-tint-80: #ccf3fe;
  --bs-primary-tint-60: #99e7fd;
  --bs-primary-tint-40: #66dbfb;
  --bs-primary-tint-20: #33cffa;
  --bs-primary-rgb: 0, 195, 249;
  --bs-primary-tint-88: #e0f8fe;
  --bs-heading-color: #110c33;
  --bs-info: #573bff;
  --bs-info-shade-80: #110c33;
  --bs-info-shade-60: #231866;
  --bs-info-shade-40: #342399;
  --bs-info-shade-20: #462fcc;
  --bs-info-tint-80: #ddd8ff;
  --bs-info-tint-60: #bcb1ff;
  --bs-info-tint-40: #9a89ff;
  --bs-info-tint-20: #7962ff;
  --bs-info-rgb: 87, 59, 255;
  --bs-info-tint-88: #ebe7ff;
}

:root .theme-color-gray {
  --bs-heading-color: #0c112e;
  --bs-blue: #d94f66;
  --bs-blue-shade-80: #0c112e;
  --bs-blue-shade-60: #17235d;
  --bs-blue-shade-40: #23348b;
  --bs-blue-shade-20: #2e46ba;
  --bs-blue-tint-80: #d8ddfa;
  --bs-blue-tint-60: #b0bcf6;
  --bs-blue-tint-40: #899af1;
  --bs-blue-tint-20: #6179ed;
  --bs-blue-rgb: 58, 87, 232;
  --bs-blue-tint-88: #e7ebfc;
  --bs-heading-color: #140330;
  --bs-indigo: #6610f2;
  --bs-indigo-shade-80: #140330;
  --bs-indigo-shade-60: #290661;
  --bs-indigo-shade-40: #3d0a91;
  --bs-indigo-shade-20: #520dc2;
  --bs-indigo-tint-80: #e0cffc;
  --bs-indigo-tint-60: #c29ffa;
  --bs-indigo-tint-40: #a370f7;
  --bs-indigo-tint-20: #8540f5;
  --bs-indigo-rgb: 102, 16, 242;
  --bs-indigo-tint-88: #ede2fd;
  --bs-heading-color: #160d27;
  --bs-purple: #6f42c1;
  --bs-purple-shade-80: #160d27;
  --bs-purple-shade-60: #2c1a4d;
  --bs-purple-shade-40: #432874;
  --bs-purple-shade-20: #59359a;
  --bs-purple-tint-80: #e2d9f3;
  --bs-purple-tint-60: #c5b3e6;
  --bs-purple-tint-40: #a98eda;
  --bs-purple-tint-20: #8c68cd;
  --bs-purple-rgb: 111, 66, 193;
  --bs-purple-tint-88: #eee8f8;
  --bs-heading-color: #2b0a1a;
  --bs-pink: #d63384;
  --bs-pink-shade-80: #2b0a1a;
  --bs-pink-shade-60: #561435;
  --bs-pink-shade-40: #801f4f;
  --bs-pink-shade-20: #ab296a;
  --bs-pink-tint-80: #f7d6e6;
  --bs-pink-tint-60: #efadce;
  --bs-pink-tint-40: #e685b5;
  --bs-pink-tint-20: #de5c9d;
  --bs-pink-rgb: 214, 51, 132;
  --bs-pink-tint-88: #fae7f0;
  --bs-heading-color: #260a07;
  --bs-red: #c03221;
  --bs-red-shade-80: #260a07;
  --bs-red-shade-60: #4d140d;
  --bs-red-shade-40: #731e14;
  --bs-red-shade-20: #9a281a;
  --bs-red-tint-80: #f2d6d3;
  --bs-red-tint-60: #e6ada6;
  --bs-red-tint-40: #d9847a;
  --bs-red-tint-20: #cd5b4d;
  --bs-red-rgb: 192, 50, 33;
  --bs-red-tint-88: #f7e6e4;
  --bs-heading-color: #32220b;
  --bs-orange: #faa938;
  --bs-orange-shade-80: #32220b;
  --bs-orange-shade-60: #644416;
  --bs-orange-shade-40: #966522;
  --bs-orange-shade-20: #c8872d;
  --bs-orange-tint-80: #feeed7;
  --bs-orange-tint-60: #fdddaf;
  --bs-orange-tint-40: #fccb88;
  --bs-orange-tint-20: #fbba60;
  --bs-orange-rgb: 250, 169, 56;
  --bs-orange-tint-88: #fef5e7;
  --bs-heading-color: #301505;
  --bs-yellow: #f16a1b;
  --bs-yellow-shade-80: #301505;
  --bs-yellow-shade-60: #602a0b;
  --bs-yellow-shade-40: #914010;
  --bs-yellow-shade-20: #c15516;
  --bs-yellow-tint-80: #fce1d1;
  --bs-yellow-tint-60: #f9c3a4;
  --bs-yellow-tint-40: #f7a676;
  --bs-yellow-tint-20: #f48849;
  --bs-yellow-rgb: 241, 106, 27;
  --bs-yellow-tint-88: #fdede4;
  --bs-heading-color: #052011;
  --bs-green: #1aa053;
  --bs-green-shade-80: #052011;
  --bs-green-shade-60: #0a4021;
  --bs-green-shade-40: #106032;
  --bs-green-shade-20: #158042;
  --bs-green-tint-80: #d1ecdd;
  --bs-green-tint-60: #a3d9ba;
  --bs-green-tint-40: #76c698;
  --bs-green-tint-20: #48b375;
  --bs-green-rgb: 26, 160, 83;
  --bs-green-tint-88: #e4f4ea;
  --bs-heading-color: #06281e;
  --bs-teal: #20c997;
  --bs-teal-shade-80: #06281e;
  --bs-teal-shade-60: #0d503c;
  --bs-teal-shade-40: #13795b;
  --bs-teal-shade-20: #1aa179;
  --bs-teal-tint-80: #d2f4ea;
  --bs-teal-tint-60: #a6e9d5;
  --bs-teal-tint-40: #79dfc1;
  --bs-teal-tint-20: #4dd4ac;
  --bs-teal-rgb: 32, 201, 151;
  --bs-teal-tint-88: #e4f9f3;
  --bs-heading-color: #011f20;
  --bs-cyan: #079aa2;
  --bs-cyan-shade-80: #011f20;
  --bs-cyan-shade-60: #033e41;
  --bs-cyan-shade-40: #045c61;
  --bs-cyan-shade-20: #067b82;
  --bs-cyan-tint-80: #cdebec;
  --bs-cyan-tint-60: #9cd7da;
  --bs-cyan-tint-40: #6ac2c7;
  --bs-cyan-tint-20: #39aeb5;
  --bs-cyan-rgb: 7, 154, 162;
  --bs-cyan-tint-88: #e1f3f4;
  --bs-heading-color: #333333;
  --bs-white: #fff;
  --bs-white-shade-80: #333333;
  --bs-white-shade-60: #666666;
  --bs-white-shade-40: #999999;
  --bs-white-shade-20: #cccccc;
  --bs-white-tint-80: white;
  --bs-white-tint-60: white;
  --bs-white-tint-40: white;
  --bs-white-tint-20: white;
  --bs-white-rgb: 255, 255, 255;
  --bs-white-tint-88: white;
  --bs-heading-color: #161719;
  --bs-gray: #6c757d;
  --bs-gray-shade-80: #161719;
  --bs-gray-shade-60: #2b2f32;
  --bs-gray-shade-40: #41464b;
  --bs-gray-shade-20: #565e64;
  --bs-gray-tint-80: #e2e3e5;
  --bs-gray-tint-60: #c4c8cb;
  --bs-gray-tint-40: #a7acb1;
  --bs-gray-tint-20: #899197;
  --bs-gray-rgb: 108, 117, 125;
  --bs-gray-tint-88: #edeeef;
  --bs-heading-color: #0a0c0d;
  --bs-gray-dark: #343a40;
  --bs-gray-dark-shade-80: #0a0c0d;
  --bs-gray-dark-shade-60: #15171a;
  --bs-gray-dark-shade-40: #1f2326;
  --bs-gray-dark-shade-20: #2a2e33;
  --bs-gray-dark-tint-80: #d6d8d9;
  --bs-gray-dark-tint-60: #aeb0b3;
  --bs-gray-dark-tint-40: #85898c;
  --bs-gray-dark-tint-20: #5d6166;
  --bs-gray-dark-rgb: 52, 58, 64;
  --bs-gray-dark-tint-88: #e7e7e8;
  --bs-heading-color: #1d1e20;
  --bs-primary: #91969e;
  --bs-primary-shade-80: #1d1e20;
  --bs-primary-shade-60: #3a3c3f;
  --bs-primary-shade-40: #575a5f;
  --bs-primary-shade-20: #74787e;
  --bs-primary-tint-80: #e9eaec;
  --bs-primary-tint-60: #d3d5d8;
  --bs-primary-tint-40: #bdc0c5;
  --bs-primary-tint-20: #a7abb1;
  --bs-primary-rgb: 145, 150, 158;
  --bs-primary-tint-88: #f2f2f3;
  --bs-heading-color: #331c00;
  --bs-info: #fd8d00;
  --bs-info-shade-80: #331c00;
  --bs-info-shade-60: #653800;
  --bs-info-shade-40: #985500;
  --bs-info-shade-20: #ca7100;
  --bs-info-tint-80: #ffe8cc;
  --bs-info-tint-60: #fed199;
  --bs-info-tint-40: #febb66;
  --bs-info-tint-20: #fda433;
  --bs-info-rgb: 253, 141, 0;
  --bs-info-tint-88: #fff1e0;
}

:root .theme-color-red {
  --bs-heading-color: #0c112e;
  --bs-blue: #d94f66;
  --bs-blue-shade-80: #0c112e;
  --bs-blue-shade-60: #17235d;
  --bs-blue-shade-40: #23348b;
  --bs-blue-shade-20: #2e46ba;
  --bs-blue-tint-80: #d8ddfa;
  --bs-blue-tint-60: #b0bcf6;
  --bs-blue-tint-40: #899af1;
  --bs-blue-tint-20: #6179ed;
  --bs-blue-rgb: 58, 87, 232;
  --bs-blue-tint-88: #e7ebfc;
  --bs-heading-color: #140330;
  --bs-indigo: #6610f2;
  --bs-indigo-shade-80: #140330;
  --bs-indigo-shade-60: #290661;
  --bs-indigo-shade-40: #3d0a91;
  --bs-indigo-shade-20: #520dc2;
  --bs-indigo-tint-80: #e0cffc;
  --bs-indigo-tint-60: #c29ffa;
  --bs-indigo-tint-40: #a370f7;
  --bs-indigo-tint-20: #8540f5;
  --bs-indigo-rgb: 102, 16, 242;
  --bs-indigo-tint-88: #ede2fd;
  --bs-heading-color: #160d27;
  --bs-purple: #6f42c1;
  --bs-purple-shade-80: #160d27;
  --bs-purple-shade-60: #2c1a4d;
  --bs-purple-shade-40: #432874;
  --bs-purple-shade-20: #59359a;
  --bs-purple-tint-80: #e2d9f3;
  --bs-purple-tint-60: #c5b3e6;
  --bs-purple-tint-40: #a98eda;
  --bs-purple-tint-20: #8c68cd;
  --bs-purple-rgb: 111, 66, 193;
  --bs-purple-tint-88: #eee8f8;
  --bs-heading-color: #2b0a1a;
  --bs-pink: #d63384;
  --bs-pink-shade-80: #2b0a1a;
  --bs-pink-shade-60: #561435;
  --bs-pink-shade-40: #801f4f;
  --bs-pink-shade-20: #ab296a;
  --bs-pink-tint-80: #f7d6e6;
  --bs-pink-tint-60: #efadce;
  --bs-pink-tint-40: #e685b5;
  --bs-pink-tint-20: #de5c9d;
  --bs-pink-rgb: 214, 51, 132;
  --bs-pink-tint-88: #fae7f0;
  --bs-heading-color: #260a07;
  --bs-red: #c03221;
  --bs-red-shade-80: #260a07;
  --bs-red-shade-60: #4d140d;
  --bs-red-shade-40: #731e14;
  --bs-red-shade-20: #9a281a;
  --bs-red-tint-80: #f2d6d3;
  --bs-red-tint-60: #e6ada6;
  --bs-red-tint-40: #d9847a;
  --bs-red-tint-20: #cd5b4d;
  --bs-red-rgb: 192, 50, 33;
  --bs-red-tint-88: #f7e6e4;
  --bs-heading-color: #32220b;
  --bs-orange: #faa938;
  --bs-orange-shade-80: #32220b;
  --bs-orange-shade-60: #644416;
  --bs-orange-shade-40: #966522;
  --bs-orange-shade-20: #c8872d;
  --bs-orange-tint-80: #feeed7;
  --bs-orange-tint-60: #fdddaf;
  --bs-orange-tint-40: #fccb88;
  --bs-orange-tint-20: #fbba60;
  --bs-orange-rgb: 250, 169, 56;
  --bs-orange-tint-88: #fef5e7;
  --bs-heading-color: #301505;
  --bs-yellow: #f16a1b;
  --bs-yellow-shade-80: #301505;
  --bs-yellow-shade-60: #602a0b;
  --bs-yellow-shade-40: #914010;
  --bs-yellow-shade-20: #c15516;
  --bs-yellow-tint-80: #fce1d1;
  --bs-yellow-tint-60: #f9c3a4;
  --bs-yellow-tint-40: #f7a676;
  --bs-yellow-tint-20: #f48849;
  --bs-yellow-rgb: 241, 106, 27;
  --bs-yellow-tint-88: #fdede4;
  --bs-heading-color: #052011;
  --bs-green: #1aa053;
  --bs-green-shade-80: #052011;
  --bs-green-shade-60: #0a4021;
  --bs-green-shade-40: #106032;
  --bs-green-shade-20: #158042;
  --bs-green-tint-80: #d1ecdd;
  --bs-green-tint-60: #a3d9ba;
  --bs-green-tint-40: #76c698;
  --bs-green-tint-20: #48b375;
  --bs-green-rgb: 26, 160, 83;
  --bs-green-tint-88: #e4f4ea;
  --bs-heading-color: #06281e;
  --bs-teal: #20c997;
  --bs-teal-shade-80: #06281e;
  --bs-teal-shade-60: #0d503c;
  --bs-teal-shade-40: #13795b;
  --bs-teal-shade-20: #1aa179;
  --bs-teal-tint-80: #d2f4ea;
  --bs-teal-tint-60: #a6e9d5;
  --bs-teal-tint-40: #79dfc1;
  --bs-teal-tint-20: #4dd4ac;
  --bs-teal-rgb: 32, 201, 151;
  --bs-teal-tint-88: #e4f9f3;
  --bs-heading-color: #011f20;
  --bs-cyan: #079aa2;
  --bs-cyan-shade-80: #011f20;
  --bs-cyan-shade-60: #033e41;
  --bs-cyan-shade-40: #045c61;
  --bs-cyan-shade-20: #067b82;
  --bs-cyan-tint-80: #cdebec;
  --bs-cyan-tint-60: #9cd7da;
  --bs-cyan-tint-40: #6ac2c7;
  --bs-cyan-tint-20: #39aeb5;
  --bs-cyan-rgb: 7, 154, 162;
  --bs-cyan-tint-88: #e1f3f4;
  --bs-heading-color: #333333;
  --bs-white: #fff;
  --bs-white-shade-80: #333333;
  --bs-white-shade-60: #666666;
  --bs-white-shade-40: #999999;
  --bs-white-shade-20: #cccccc;
  --bs-white-tint-80: white;
  --bs-white-tint-60: white;
  --bs-white-tint-40: white;
  --bs-white-tint-20: white;
  --bs-white-rgb: 255, 255, 255;
  --bs-white-tint-88: white;
  --bs-heading-color: #161719;
  --bs-gray: #6c757d;
  --bs-gray-shade-80: #161719;
  --bs-gray-shade-60: #2b2f32;
  --bs-gray-shade-40: #41464b;
  --bs-gray-shade-20: #565e64;
  --bs-gray-tint-80: #e2e3e5;
  --bs-gray-tint-60: #c4c8cb;
  --bs-gray-tint-40: #a7acb1;
  --bs-gray-tint-20: #899197;
  --bs-gray-rgb: 108, 117, 125;
  --bs-gray-tint-88: #edeeef;
  --bs-heading-color: #0a0c0d;
  --bs-gray-dark: #343a40;
  --bs-gray-dark-shade-80: #0a0c0d;
  --bs-gray-dark-shade-60: #15171a;
  --bs-gray-dark-shade-40: #1f2326;
  --bs-gray-dark-shade-20: #2a2e33;
  --bs-gray-dark-tint-80: #d6d8d9;
  --bs-gray-dark-tint-60: #aeb0b3;
  --bs-gray-dark-tint-40: #85898c;
  --bs-gray-dark-tint-20: #5d6166;
  --bs-gray-dark-rgb: 52, 58, 64;
  --bs-gray-dark-tint-88: #e7e7e8;
  --bs-heading-color: #2c1114;
  --bs-primary: #d94f66;
  --bs-secondary: #734761;
  --bs-primary-shade-80: #2c1114;
  --bs-primary-shade-60: #582128;
  --bs-primary-shade-40: #83323b;
  --bs-primary-shade-20: #af424f;
  --bs-primary-tint-80: #f8dde0;
  --bs-primary-tint-60: #f1bac1;
  --bs-primary-tint-40: #e998a1;
  --bs-primary-tint-20: #e27582;
  --bs-primary-rgb: 219, 83, 99;
  --bs-primary-tint-88: #fbeaec;
  --bs-heading-color: #0b1530;
  --bs-info: #366af0;
  --bs-info-shade-80: #0b1530;
  --bs-info-shade-60: #162a60;
  --bs-info-shade-40: #204090;
  --bs-info-shade-20: #2b55c0;
  --bs-info-tint-80: #d7e1fc;
  --bs-info-tint-60: #afc3f9;
  --bs-info-tint-40: #86a6f6;
  --bs-info-tint-20: #5e88f3;
  --bs-info-rgb: 54, 106, 240;
  --bs-info-tint-88: #e7edfd;
}

:root .theme-color-yellow {
  --bs-heading-color: #0c112e;
  --bs-blue: #d94f66;
  --bs-blue-shade-80: #0c112e;
  --bs-blue-shade-60: #17235d;
  --bs-blue-shade-40: #23348b;
  --bs-blue-shade-20: #2e46ba;
  --bs-blue-tint-80: #d8ddfa;
  --bs-blue-tint-60: #b0bcf6;
  --bs-blue-tint-40: #899af1;
  --bs-blue-tint-20: #6179ed;
  --bs-blue-rgb: 58, 87, 232;
  --bs-blue-tint-88: #e7ebfc;
  --bs-heading-color: #140330;
  --bs-indigo: #6610f2;
  --bs-indigo-shade-80: #140330;
  --bs-indigo-shade-60: #290661;
  --bs-indigo-shade-40: #3d0a91;
  --bs-indigo-shade-20: #520dc2;
  --bs-indigo-tint-80: #e0cffc;
  --bs-indigo-tint-60: #c29ffa;
  --bs-indigo-tint-40: #a370f7;
  --bs-indigo-tint-20: #8540f5;
  --bs-indigo-rgb: 102, 16, 242;
  --bs-indigo-tint-88: #ede2fd;
  --bs-heading-color: #160d27;
  --bs-purple: #6f42c1;
  --bs-purple-shade-80: #160d27;
  --bs-purple-shade-60: #2c1a4d;
  --bs-purple-shade-40: #432874;
  --bs-purple-shade-20: #59359a;
  --bs-purple-tint-80: #e2d9f3;
  --bs-purple-tint-60: #c5b3e6;
  --bs-purple-tint-40: #a98eda;
  --bs-purple-tint-20: #8c68cd;
  --bs-purple-rgb: 111, 66, 193;
  --bs-purple-tint-88: #eee8f8;
  --bs-heading-color: #2b0a1a;
  --bs-pink: #d63384;
  --bs-pink-shade-80: #2b0a1a;
  --bs-pink-shade-60: #561435;
  --bs-pink-shade-40: #801f4f;
  --bs-pink-shade-20: #ab296a;
  --bs-pink-tint-80: #f7d6e6;
  --bs-pink-tint-60: #efadce;
  --bs-pink-tint-40: #e685b5;
  --bs-pink-tint-20: #de5c9d;
  --bs-pink-rgb: 214, 51, 132;
  --bs-pink-tint-88: #fae7f0;
  --bs-heading-color: #260a07;
  --bs-red: #c03221;
  --bs-red-shade-80: #260a07;
  --bs-red-shade-60: #4d140d;
  --bs-red-shade-40: #731e14;
  --bs-red-shade-20: #9a281a;
  --bs-red-tint-80: #f2d6d3;
  --bs-red-tint-60: #e6ada6;
  --bs-red-tint-40: #d9847a;
  --bs-red-tint-20: #cd5b4d;
  --bs-red-rgb: 192, 50, 33;
  --bs-red-tint-88: #f7e6e4;
  --bs-heading-color: #32220b;
  --bs-orange: #faa938;
  --bs-orange-shade-80: #32220b;
  --bs-orange-shade-60: #644416;
  --bs-orange-shade-40: #966522;
  --bs-orange-shade-20: #c8872d;
  --bs-orange-tint-80: #feeed7;
  --bs-orange-tint-60: #fdddaf;
  --bs-orange-tint-40: #fccb88;
  --bs-orange-tint-20: #fbba60;
  --bs-orange-rgb: 250, 169, 56;
  --bs-orange-tint-88: #fef5e7;
  --bs-heading-color: #301505;
  --bs-yellow: #f16a1b;
  --bs-yellow-shade-80: #301505;
  --bs-yellow-shade-60: #602a0b;
  --bs-yellow-shade-40: #914010;
  --bs-yellow-shade-20: #c15516;
  --bs-yellow-tint-80: #fce1d1;
  --bs-yellow-tint-60: #f9c3a4;
  --bs-yellow-tint-40: #f7a676;
  --bs-yellow-tint-20: #f48849;
  --bs-yellow-rgb: 241, 106, 27;
  --bs-yellow-tint-88: #fdede4;
  --bs-heading-color: #052011;
  --bs-green: #1aa053;
  --bs-green-shade-80: #052011;
  --bs-green-shade-60: #0a4021;
  --bs-green-shade-40: #106032;
  --bs-green-shade-20: #158042;
  --bs-green-tint-80: #d1ecdd;
  --bs-green-tint-60: #a3d9ba;
  --bs-green-tint-40: #76c698;
  --bs-green-tint-20: #48b375;
  --bs-green-rgb: 26, 160, 83;
  --bs-green-tint-88: #e4f4ea;
  --bs-heading-color: #06281e;
  --bs-teal: #20c997;
  --bs-teal-shade-80: #06281e;
  --bs-teal-shade-60: #0d503c;
  --bs-teal-shade-40: #13795b;
  --bs-teal-shade-20: #1aa179;
  --bs-teal-tint-80: #d2f4ea;
  --bs-teal-tint-60: #a6e9d5;
  --bs-teal-tint-40: #79dfc1;
  --bs-teal-tint-20: #4dd4ac;
  --bs-teal-rgb: 32, 201, 151;
  --bs-teal-tint-88: #e4f9f3;
  --bs-heading-color: #011f20;
  --bs-cyan: #079aa2;
  --bs-cyan-shade-80: #011f20;
  --bs-cyan-shade-60: #033e41;
  --bs-cyan-shade-40: #045c61;
  --bs-cyan-shade-20: #067b82;
  --bs-cyan-tint-80: #cdebec;
  --bs-cyan-tint-60: #9cd7da;
  --bs-cyan-tint-40: #6ac2c7;
  --bs-cyan-tint-20: #39aeb5;
  --bs-cyan-rgb: 7, 154, 162;
  --bs-cyan-tint-88: #e1f3f4;
  --bs-heading-color: #333333;
  --bs-white: #fff;
  --bs-white-shade-80: #333333;
  --bs-white-shade-60: #666666;
  --bs-white-shade-40: #999999;
  --bs-white-shade-20: #cccccc;
  --bs-white-tint-80: white;
  --bs-white-tint-60: white;
  --bs-white-tint-40: white;
  --bs-white-tint-20: white;
  --bs-white-rgb: 255, 255, 255;
  --bs-white-tint-88: white;
  --bs-heading-color: #161719;
  --bs-gray: #6c757d;
  --bs-gray-shade-80: #161719;
  --bs-gray-shade-60: #2b2f32;
  --bs-gray-shade-40: #41464b;
  --bs-gray-shade-20: #565e64;
  --bs-gray-tint-80: #e2e3e5;
  --bs-gray-tint-60: #c4c8cb;
  --bs-gray-tint-40: #a7acb1;
  --bs-gray-tint-20: #899197;
  --bs-gray-rgb: 108, 117, 125;
  --bs-gray-tint-88: #edeeef;
  --bs-heading-color: #0a0c0d;
  --bs-gray-dark: #343a40;
  --bs-gray-dark-shade-80: #0a0c0d;
  --bs-gray-dark-shade-60: #15171a;
  --bs-gray-dark-shade-40: #1f2326;
  --bs-gray-dark-shade-20: #2a2e33;
  --bs-gray-dark-tint-80: #d6d8d9;
  --bs-gray-dark-tint-60: #aeb0b3;
  --bs-gray-dark-tint-40: #85898c;
  --bs-gray-dark-tint-20: #5d6166;
  --bs-gray-dark-rgb: 52, 58, 64;
  --bs-gray-dark-tint-88: #e7e7e8;
  --bs-heading-color: #2f1504;
  --bs-primary: #ea6a12;
  --bs-primary-shade-80: #2f1504;
  --bs-primary-shade-60: #5e2a07;
  --bs-primary-shade-40: #8c400b;
  --bs-primary-shade-20: #bb550e;
  --bs-primary-tint-80: #fbe1d0;
  --bs-primary-tint-60: #f7c3a0;
  --bs-primary-tint-40: #f2a671;
  --bs-primary-tint-20: #ee8841;
  --bs-primary-rgb: 234, 106, 18;
  --bs-primary-tint-88: #fcede3;
  --bs-heading-color: #140330;
  --bs-info: #6410f1;
  --bs-info-shade-80: #140330;
  --bs-info-shade-60: #280660;
  --bs-info-shade-40: #3c0a91;
  --bs-info-shade-20: #500dc1;
  --bs-info-tint-80: #e0cffc;
  --bs-info-tint-60: #c19ff9;
  --bs-info-tint-40: #a270f7;
  --bs-info-tint-20: #8340f4;
  --bs-info-rgb: 100, 16, 241;
  --bs-info-tint-88: #ece2fd;
}

:root .theme-color-pink {
  --bs-heading-color: #0c112e;
  --bs-blue: #d94f66;
  --bs-blue-shade-80: #0c112e;
  --bs-blue-shade-60: #17235d;
  --bs-blue-shade-40: #23348b;
  --bs-blue-shade-20: #2e46ba;
  --bs-blue-tint-80: #d8ddfa;
  --bs-blue-tint-60: #b0bcf6;
  --bs-blue-tint-40: #899af1;
  --bs-blue-tint-20: #6179ed;
  --bs-blue-rgb: 58, 87, 232;
  --bs-blue-tint-88: #e7ebfc;
  --bs-heading-color: #140330;
  --bs-indigo: #6610f2;
  --bs-indigo-shade-80: #140330;
  --bs-indigo-shade-60: #290661;
  --bs-indigo-shade-40: #3d0a91;
  --bs-indigo-shade-20: #520dc2;
  --bs-indigo-tint-80: #e0cffc;
  --bs-indigo-tint-60: #c29ffa;
  --bs-indigo-tint-40: #a370f7;
  --bs-indigo-tint-20: #8540f5;
  --bs-indigo-rgb: 102, 16, 242;
  --bs-indigo-tint-88: #ede2fd;
  --bs-heading-color: #160d27;
  --bs-purple: #6f42c1;
  --bs-purple-shade-80: #160d27;
  --bs-purple-shade-60: #2c1a4d;
  --bs-purple-shade-40: #432874;
  --bs-purple-shade-20: #59359a;
  --bs-purple-tint-80: #e2d9f3;
  --bs-purple-tint-60: #c5b3e6;
  --bs-purple-tint-40: #a98eda;
  --bs-purple-tint-20: #8c68cd;
  --bs-purple-rgb: 111, 66, 193;
  --bs-purple-tint-88: #eee8f8;
  --bs-heading-color: #2b0a1a;
  --bs-pink: #d63384;
  --bs-pink-shade-80: #2b0a1a;
  --bs-pink-shade-60: #561435;
  --bs-pink-shade-40: #801f4f;
  --bs-pink-shade-20: #ab296a;
  --bs-pink-tint-80: #f7d6e6;
  --bs-pink-tint-60: #efadce;
  --bs-pink-tint-40: #e685b5;
  --bs-pink-tint-20: #de5c9d;
  --bs-pink-rgb: 214, 51, 132;
  --bs-pink-tint-88: #fae7f0;
  --bs-heading-color: #260a07;
  --bs-red: #c03221;
  --bs-red-shade-80: #260a07;
  --bs-red-shade-60: #4d140d;
  --bs-red-shade-40: #731e14;
  --bs-red-shade-20: #9a281a;
  --bs-red-tint-80: #f2d6d3;
  --bs-red-tint-60: #e6ada6;
  --bs-red-tint-40: #d9847a;
  --bs-red-tint-20: #cd5b4d;
  --bs-red-rgb: 192, 50, 33;
  --bs-red-tint-88: #f7e6e4;
  --bs-heading-color: #32220b;
  --bs-orange: #faa938;
  --bs-orange-shade-80: #32220b;
  --bs-orange-shade-60: #644416;
  --bs-orange-shade-40: #966522;
  --bs-orange-shade-20: #c8872d;
  --bs-orange-tint-80: #feeed7;
  --bs-orange-tint-60: #fdddaf;
  --bs-orange-tint-40: #fccb88;
  --bs-orange-tint-20: #fbba60;
  --bs-orange-rgb: 250, 169, 56;
  --bs-orange-tint-88: #fef5e7;
  --bs-heading-color: #301505;
  --bs-yellow: #f16a1b;
  --bs-yellow-shade-80: #301505;
  --bs-yellow-shade-60: #602a0b;
  --bs-yellow-shade-40: #914010;
  --bs-yellow-shade-20: #c15516;
  --bs-yellow-tint-80: #fce1d1;
  --bs-yellow-tint-60: #f9c3a4;
  --bs-yellow-tint-40: #f7a676;
  --bs-yellow-tint-20: #f48849;
  --bs-yellow-rgb: 241, 106, 27;
  --bs-yellow-tint-88: #fdede4;
  --bs-heading-color: #052011;
  --bs-green: #1aa053;
  --bs-green-shade-80: #052011;
  --bs-green-shade-60: #0a4021;
  --bs-green-shade-40: #106032;
  --bs-green-shade-20: #158042;
  --bs-green-tint-80: #d1ecdd;
  --bs-green-tint-60: #a3d9ba;
  --bs-green-tint-40: #76c698;
  --bs-green-tint-20: #48b375;
  --bs-green-rgb: 26, 160, 83;
  --bs-green-tint-88: #e4f4ea;
  --bs-heading-color: #06281e;
  --bs-teal: #20c997;
  --bs-teal-shade-80: #06281e;
  --bs-teal-shade-60: #0d503c;
  --bs-teal-shade-40: #13795b;
  --bs-teal-shade-20: #1aa179;
  --bs-teal-tint-80: #d2f4ea;
  --bs-teal-tint-60: #a6e9d5;
  --bs-teal-tint-40: #79dfc1;
  --bs-teal-tint-20: #4dd4ac;
  --bs-teal-rgb: 32, 201, 151;
  --bs-teal-tint-88: #e4f9f3;
  --bs-heading-color: #011f20;
  --bs-cyan: #079aa2;
  --bs-cyan-shade-80: #011f20;
  --bs-cyan-shade-60: #033e41;
  --bs-cyan-shade-40: #045c61;
  --bs-cyan-shade-20: #067b82;
  --bs-cyan-tint-80: #cdebec;
  --bs-cyan-tint-60: #9cd7da;
  --bs-cyan-tint-40: #6ac2c7;
  --bs-cyan-tint-20: #39aeb5;
  --bs-cyan-rgb: 7, 154, 162;
  --bs-cyan-tint-88: #e1f3f4;
  --bs-heading-color: #333333;
  --bs-white: #fff;
  --bs-white-shade-80: #333333;
  --bs-white-shade-60: #666666;
  --bs-white-shade-40: #999999;
  --bs-white-shade-20: #cccccc;
  --bs-white-tint-80: white;
  --bs-white-tint-60: white;
  --bs-white-tint-40: white;
  --bs-white-tint-20: white;
  --bs-white-rgb: 255, 255, 255;
  --bs-white-tint-88: white;
  --bs-heading-color: #161719;
  --bs-gray: #6c757d;
  --bs-gray-shade-80: #161719;
  --bs-gray-shade-60: #2b2f32;
  --bs-gray-shade-40: #41464b;
  --bs-gray-shade-20: #565e64;
  --bs-gray-tint-80: #e2e3e5;
  --bs-gray-tint-60: #c4c8cb;
  --bs-gray-tint-40: #a7acb1;
  --bs-gray-tint-20: #899197;
  --bs-gray-rgb: 108, 117, 125;
  --bs-gray-tint-88: #edeeef;
  --bs-heading-color: #0a0c0d;
  --bs-gray-dark: #343a40;
  --bs-gray-dark-shade-80: #0a0c0d;
  --bs-gray-dark-shade-60: #15171a;
  --bs-gray-dark-shade-40: #1f2326;
  --bs-gray-dark-shade-20: #2a2e33;
  --bs-gray-dark-tint-80: #d6d8d9;
  --bs-gray-dark-tint-60: #aeb0b3;
  --bs-gray-dark-tint-40: #85898c;
  --bs-gray-dark-tint-20: #5d6166;
  --bs-gray-dark-rgb: 52, 58, 64;
  --bs-gray-dark-tint-88: #e7e7e8;
  --bs-heading-color: #2e1b24;
  --bs-primary: #e586b3;
  --bs-primary-shade-80: #2e1b24;
  --bs-primary-shade-60: #5c3648;
  --bs-primary-shade-40: #89506b;
  --bs-primary-shade-20: #b76b8f;
  --bs-primary-tint-80: #fae7f0;
  --bs-primary-tint-60: #f5cfe1;
  --bs-primary-tint-40: #efb6d1;
  --bs-primary-tint-20: #ea9ec2;
  --bs-primary-rgb: 229, 134, 179;
  --bs-primary-tint-88: #fcf0f6;
  --bs-heading-color: #07281f;
  --bs-info: #25c799;
  --bs-info-shade-80: #07281f;
  --bs-info-shade-60: #0f503d;
  --bs-info-shade-40: #16775c;
  --bs-info-shade-20: #1e9f7a;
  --bs-info-tint-80: #d3f4eb;
  --bs-info-tint-60: #a8e9d6;
  --bs-info-tint-40: #7cddc2;
  --bs-info-tint-20: #51d2ad;
  --bs-info-rgb: 37, 199, 153;
  --bs-info-tint-88: #e5f8f3;
}

.theme-color-blue .form-check-input:checked[type="checkbox"] {
  background-color: theme-color-blue;
}

.theme-color-blue .form-switch .form-check-input {
  padding: 0.5rem;
  background-color: #e9ecef;
  border-color: #e9ecef;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.theme-color-blue .form-switch .form-check-input:checked {
  background-color: var(--bs-primary-tint-80);
  border-color: var(--bs-primary-tint-80);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.theme-color-gray .form-check-input:checked[type="checkbox"] {
  background-color: theme-color-gray;
}

.theme-color-gray .form-switch .form-check-input {
  padding: 0.5rem;
  background-color: #e9ecef;
  border-color: #e9ecef;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.theme-color-gray .form-switch .form-check-input:checked {
  background-color: var(--bs-primary-tint-80);
  border-color: var(--bs-primary-tint-80);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.theme-color-red .form-check-input:checked[type="checkbox"] {
  background-color: theme-color-red;
}

.theme-color-red .form-switch .form-check-input {
  padding: 0.5rem;
  background-color: #e9ecef;
  border-color: #e9ecef;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.theme-color-red .form-switch .form-check-input:checked {
  background-color: var(--bs-primary-tint-80);
  border-color: var(--bs-primary-tint-80);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.theme-color-yellow .form-check-input:checked[type="checkbox"] {
  background-color: theme-color-yellow;
}

.theme-color-yellow .form-switch .form-check-input {
  padding: 0.5rem;
  background-color: #e9ecef;
  border-color: #e9ecef;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.theme-color-yellow .form-switch .form-check-input:checked {
  background-color: var(--bs-primary-tint-80);
  border-color: var(--bs-primary-tint-80);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.theme-color-pink .form-check-input:checked[type="checkbox"] {
  background-color: theme-color-pink;
}

.theme-color-pink .form-switch .form-check-input {
  padding: 0.5rem;
  background-color: #e9ecef;
  border-color: #e9ecef;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.theme-color-pink .form-switch .form-check-input:checked {
  background-color: var(--bs-primary-tint-80);
  border-color: var(--bs-primary-tint-80);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.text-primary {
  color: var(--bs-primary) !important;
}

.bg-primary {
  background-color: var(--bs-primary) !important;
}

.bg-soft-primary {
  color: var(--bs-primary);
  background-color: rgba(var(--bs-primary-rgb), 0.1) !important;
}

.text-info {
  color: var(--bs-info) !important;
}

.bg-info {
  background-color: var(--bs-info) !important;
}

.bg-soft-info {
  color: var(--bs-info);
  background-color: rgba(var(--bs-info-rgb), 0.1) !important;
}

.accordion-item {
  background-color: var(--bs-body-bg);
}

.accordion-header {
  background-color: var(--bs-body-bg);
}

.accordion-button {
  background-color: var(--bs-body-bg);
}

.accordion-button:not(.collapsed) {
  color: var(--bs-primary-shade-20);
  background-color: var(--bs-primary-tint-80);
}

.accordion-button:focus {
  border-color: var(--bs-primary-tint-60);
}

/* Swiper Css Only */

.d-slider1 .swiper-button-prev:after,
.d-slider1 .swiper-button-next:after {
  color: var(--bg-bg-light1) !important;
}

.swiper-container:after {
  animation: line-slide 60s forwards infinite linear;
}

.swiper-container.swiper--bottom:after {
  animation-direction: reverse;
}

.swiper-wrapper {
  transition-timing-function: linear !important;
  position: relative;
}

@keyframes line-slide {
  0% {
    background-position: -5% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

.alert-primary {
  color: var(--bs-primary);
  background: var(--bs-primary-tint-80);
  border-color: var(--bs-primary);
}

.alert-primary .alert-link {
  color: var(--bs-primary-shade-40);
}

.alert-primary.alert-solid {
  color: var(--bs-white);
  background: var(--bs-primary);
  border-color: var(--bs-primary);
}

.alert-primary.alert-left {
  background: rgba(var(--bs-primary-rgb), 0.2);
  border-color: var(--bs-primary);
}

.iq-media-group .iq-icon-box-3 {
  border-color: var(--bs-primary);
}

.badge {
  color: var(--bs-white);
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--bs-primary);
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-white);
  background-color: var(--bs-primary);
}

.btn.btn-primary {
  color: var(--bs-white);
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.btn-shadow {
  box-shadow: var(--btn-shadow);
}

.btn.btn-primary:hover {
  color: var(--bs-white);
  background-color: var(--bs-secondary);
  border-color: var(--bs-secondary);
}

.btn.btn-primary:focus {
  color: var(--bs-white);
  background-color: var(--bs-secondary);
  border-color: var(--bs-secondary);
}

.btn.btn-primary:active,
.btn.btn-primary.active {
  color: var(--bs-white);
  border-color: var(--bs-secondary);
  border-color: var(--bs-secondary);
}

.btn.btn-outline-primary {
  color: var(--bs-primary-shade-20);
  border-color: var(--bs-primary-shade-20);
}

.btn.btn-outline-primary:hover {
  color: var(--bs-white);
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.btn.btn-outline-primary:active,
.btn.btn-outline-primary.active {
  color: var(--bs-white);
  background-color: var(--bs-primary-shade-20);
  border-color: var(--bs-primary-shade-20);
}

.btn.btn-soft-primary {
  color: var(--bs-primary);
  background-color: rgba(var(--bs-primary-rgb), 0.1);
  border-color: transparent;
}

.btn.btn-soft-primary:hover,
.btn.btn-soft-primary:focus,
.btn.btn-soft-primary:active {
  color: var(--bs-primary-tint-20);
  background-color: rgba(var(--bs-primary-rgb), 0.2);
  border-color: transparent;
}

.btn.btn-info {
  color: var(--bs-white);
  background-color: var(--bs-info);
  border-color: var(--bs-info);
}

.btn.btn-info:hover {
  color: var(--bs-white);
  background-color: var(--bs-info-shade-20);
  border-color: var(--bs-info-shade-20);
}

.btn.btn-info:focus {
  color: var(--bs-white);
  background-color: var(--bs-info-shade-20);
  border-color: var(--bs-info-shade-20);
}

.btn.btn-info:active,
.btn.btn-info.active {
  color: var(--bs-white);
  background-color: var(--bs-info-shade-20);
  border-color: var(--bs-info-shade-20);
}

.btn.btn-outline-info {
  color: var(--bs-info-shade-20);
  border-color: var(--bs-info-shade-20);
}

.btn.btn-outline-info:hover {
  color: var(--bs-white);
  background-color: var(--bs-info);
  border-color: var(--bs-info);
}

.btn.btn-outline-info:active,
.btn.btn-outline-info.active {
  color: var(--bs-white);
  background-color: var(--bs-info-shade-20);
  border-color: var(--bs-info-shade-20);
}

.btn.btn-soft-info {
  color: var(--bs-info);
  background-color: rgba(var(--bs-info-rgb), 0.1);
  border-color: transparent;
}

.btn.btn-soft-info:hover,
.btn.btn-soft-info:focus,
.btn.btn-soft-info:active {
  color: var(--bs-info-tint-20);
  background-color: rgba(var(--bs-info-rgb), 0.2);
  border-color: transparent;
}

.btn-link {
  color: var(--bs-primary) !important;
}

.bd-aside .active {
  color: var(--bs-primary);
  background-color: var(--bs-primary-tint-88);
}

.bd-aside .btn:hover {
  color: var(--bs-primary);
  background-color: var(--bs-primary-tint-88);
}

.bd-aside .btn:focus {
  color: var(--bs-primary);
  background-color: var(--bs-primary-tint-88);
  -webkit-box-shadow: 0 0 0 0.063rem rgba(var(--bs-primary-rgb), 0.7);
  box-shadow: 0 0 0 0.063rem rgba(var(--bs-primary-rgb), 0.7);
}

.bd-aside a:hover {
  background-color: var(--bs-primary-tint-88);
}

.btn-border.active {
  border-color: var(--bs-primary);
}

.list-group-item-primary {
  color: var(--bs-primary-shade-40);
  background-color: var(--bs-primary-tint-80);
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: var(--bs-primary-shade-40);
  background-color: var(--bs-primary-tint-60);
}

.list-group-item.active {
  color: var(--bs-white);
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.list-group-item-action:not(.active):hover,
.list-group-item-action:not(.active):focus {
  background-color: var(--bg-body);
}

.list-group-item-action:not(.active):active {
  background-color: var(--bs-primary-tint-80);
}

.form-control {
  font-size: var(--body-font-size);
}

.form-control:focus {
  border-color: var(--bs-primary);
}

.form-select:focus {
  border-color: var(--bs-primary-tint-40);
}

.form-check-input {
  border-color: var(--bs-primary-shade-20);
}

.form-check-input:checked {
  border-color: var(--bs-primary-shade-20);
}

.form-check-input:focus {
  border-color: var(--bs-primary-shade-20);
}

.border-primary {
  border-color: var(--bs-primary) !important;
}

#top-tab-list li a {
  color: var(--bs-primary);
  background: var(--bg-bg-light1);
}

#top-tab-list li a .iq-icon {
  background: var(--bs-primary);
}

#top-tab-list li a .iq-icon {
  height: 2.125rem !important;
  width: 2.125rem !important;
  line-height: 2.125rem !important;
}

#top-tab-list li.active a {
  background: var(--bs-primary);
}

#top-tab-list li.active a .iq-icon {
  color: var(--bs-primary);
}

.nav-link {
  color: var(--bs-primary);
}

.nav-link:hover,
.nav-link:focus {
  color: var(--bs-secondary);
}

.nav-tabs .nav-link.active {
  color: var(--bs-white);
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-primary-rgb), 0.3);
  box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-primary-rgb), 0.3);
}

.nav-pills .nav-link.active {
  color: #fff;
  background-color: var(--bs-primary);
}

.nav .search-input.input-group:focus-within .input-group-text,
.nav .search-input.input-group:focus-within .form-control {
  border-color: var(--bs-primary);
}

.nav .sidebar-toggle {
  background: var(--bs-primary);
}

.iq-navbar-header.navs-bg-color .iq-header-img {
  background-color: var(--bs-primary);
}

.iq-navbar .dropdown .dropdown-menu.sub-drop .iq-sub-card:hover {
  background: var(--bs-primary-tint-88);
}

.page-item.active .page-link {
  color: var(--bs-white);
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.page-item .page-link {
  border-color: var(--bs-primary-tint-80);
  color: var(--bs-primary);
}

.table-primary {
  --bs-table-bg: var(--bs-primary-tint-80);
  border-color: var(--bs-primary-tint-80);
}

.table {
  color: var(--bs-white);
  width: 100% !important;
}

.table thead tr {
  /* background-color: var(--bg-bg-light1) !important; */
  color: black;
}

.type {
  background: var(--bs-primary);
}

.prc-box {
  background: var(--bs-primary-tint-80);
}

.prc-box.active {
  background: var(--bs-primary);
}

.prc-box.active .type {
  background: var(--bs-primary-tint-80);
}

.circle-progress-primary svg .circle-progress-value {
  stroke: var(--bs-primary);
}

.circle-progress-primary svg .circle-progress-text {
  fill: var(--bs-primary);
}

.circle-progress-info svg .circle-progress-value {
  stroke: var(--bs-info);
}

.circle-progress-info svg .circle-progress-text {
  fill: var(--bs-info);
}

.sidebar .sidebar-toggle {
  background: var(--bg-bg-light1);
}

.sidebar .navbar-brand svg {
  color: var(--bs-primary);
}

.sidebar.sidebar-color .navbar-brand svg {
  color: #fff;
}

.sidebar-default .navbar-nav .nav-item .nav-link:not(.disabled).active,
.sidebar-default
  .navbar-nav
  .nav-item
  .nav-link:not(.disabled)[aria-expanded="true"] {
  color: var(--bs-primary);
  /* box-shadow: var(--btn-shadow) */
}

.sidebar-default
  .navbar-nav
  .nav-item
  .nav-link:not(.disabled):hover:not(.active):not([aria-expanded="true"]) {
  background-color: var(--bg-bg-light1);
  color: var(--bs-primary);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.sidebar-color {
  background-color: var(--bs-primary) !important;
}

.sidebar-color .navbar-nav .nav-item .nav-link:not(.disabled).active {
  background-color: #fff;
  color: var(--bs-primary) !important;
}

.sidebar-color
  .navbar-nav
  .nav-item
  .nav-link:not(.disabled)[aria-expanded="true"] {
  background-color: #fff;
  color: var(--bs-primary) !important;
}

.sidebar-color
  .navbar-nav
  .nav-item
  .nav-link:not(.disabled):hover:not(.active):not([aria-expanded="true"]) {
  background-color: rgba(var(--bs-white-rgb), 0.1);
  color: #fff;
}

.sidebar-color .sidebar-header {
  border-bottom-color: var(--bs-primary-tint-20);
}

.dual-horizontal {
  background: var(--bs-primary);
}

.dual-horizontal .nav {
  background: var(--bs-primary);
}

.boxed {
  background: var(--bs-primary);
}

.boxed-fancy::before {
  background: var(--bs-primary);
}

.fc-button-primary {
  color: #fff !important;
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

.child-cell.active {
  color: var(--bs-primary);
  stroke: var(--bs-primary);
}

.dark .text-primary {
  color: var(--bs-primary) !important;
}

.dark .bg-primary {
  background-color: var(--bs-primary) !important;
}

.dark .bg-soft-primary {
  color: var(--bs-primary);
  background-color: rgba(var(--bs-primary-rgb), 0.1) !important;
}

.dark .text-info {
  color: var(--bs-info) !important;
}

.dark .bg-info {
  background-color: var(--bs-info) !important;
}

.dark .bg-soft-info {
  color: var(--bs-info);
  background-color: rgba(var(--bs-info-rgb), 0.1) !important;
}

.dark .accordion-item {
  background-color: var(--bs-primary-tint-88);
}

.dark .accordion-header {
  background-color: var(--bs-primary-tint-88);
}

.dark .accordion-button {
  background-color: var(--bs-primary-tint-88);
}

.dark .accordion-button:not(.collapsed) {
  color: var(--bs-primary-shade-20);
  background-color: var(--bs-primary-tint-80);
}

.dark .accordion-button:focus {
  border-color: var(--bs-primary-tint-60);
}

.dark .alert-primary {
  color: var(--bs-primary);
  background: var(--bs-primary-tint-80);
  border-color: var(--bs-primary);
}

.dark .alert-primary .alert-link {
  color: var(--bs-primary-shade-40);
}

.dark .alert-primary.alert-solid {
  color: var(--bs-white);
  background: var(--bs-primary);
  border-color: var(--bs-primary);
}

.dark .alert-primary.alert-left {
  background: rgba(var(--bs-primary-rgb), 0.2);
  border-color: var(--bs-primary);
}

.dark .iq-media-group .iq-icon-box-3 {
  border-color: var(--bs-primary);
}

.dark .badge {
  color: var(--bs-white);
}

.dark .dropdown-item:hover,
.dark .dropdown-item:focus {
  color: var(--bs-primary-shade-20);
}

.dark .dropdown-item.active,
.dark .dropdown-item:active {
  color: var(--bs-white);
  background-color: var(--bs-primary);
}

.dark .btn.btn-primary {
  color: var(--bs-white);
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.dark .btn.btn-primary:hover {
  color: var(--bs-white);
  background-color: var(--bs-primary-shade-20);
  border-color: var(--bs-primary-shade-20);
}

.dark .btn.btn-primary:focus {
  color: var(--bs-white);
  background-color: var(--bs-primary-shade-20);
  border-color: var(--bs-primary-shade-20);
}

.dark .btn.btn-primary:active,
.dark .btn.btn-primary.active {
  color: var(--bs-white);
  background-color: var(--bs-primary-shade-20);
  border-color: var(--bs-primary-shade-20);
}

.dark .btn.btn-outline-primary {
  color: var(--bs-primary-shade-20);
  border-color: var(--bs-primary-shade-20);
}

.dark .btn.btn-outline-primary:hover {
  color: var(--bs-white);
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.dark .btn.btn-outline-primary:active,
.dark .btn.btn-outline-primary.active {
  color: var(--bs-white);
  background-color: var(--bs-primary-shade-20);
  border-color: var(--bs-primary-shade-20);
}

.dark .btn.btn-soft-primary {
  color: var(--bs-primary);
  background-color: rgba(var(--bs-primary-rgb), 0.1);
  border-color: transparent;
}

.dark .btn.btn-soft-primary:hover,
.dark .btn.btn-soft-primary:focus,
.dark .btn.btn-soft-primary:active {
  color: var(--bs-primary-tint-20);
  background-color: rgba(var(--bs-primary-rgb), 0.2);
  border-color: transparent;
}

.dark .btn.btn-info {
  color: var(--bs-white);
  background-color: var(--bs-info);
  border-color: var(--bs-info);
}

.dark .btn.btn-info:hover {
  color: var(--bs-white);
  background-color: var(--bs-info-shade-20);
  border-color: var(--bs-info-shade-20);
}

.dark .btn.btn-info:focus {
  color: var(--bs-white);
  background-color: var(--bs-info-shade-20);
  border-color: var(--bs-info-shade-20);
}

.dark .btn.btn-info:active,
.dark .btn.btn-info.active {
  color: var(--bs-white);
  background-color: var(--bs-info-shade-20);
  border-color: var(--bs-info-shade-20);
}

.dark .btn.btn-outline-info {
  color: var(--bs-info-shade-20);
  border-color: var(--bs-info-shade-20);
}

.dark .btn.btn-outline-info:hover {
  color: var(--bs-white);
  background-color: var(--bs-info);
  border-color: var(--bs-info);
}

.dark .btn.btn-outline-info:active,
.dark .btn.btn-outline-info.active {
  color: var(--bs-white);
  background-color: var(--bs-info-shade-20);
  border-color: var(--bs-info-shade-20);
}

.dark .btn.btn-soft-info {
  color: var(--bs-info);
  background-color: rgba(var(--bs-info-rgb), 0.1);
  border-color: transparent;
}

.dark .btn.btn-soft-info:hover,
.dark .btn.btn-soft-info:focus,
.dark .btn.btn-soft-info:active {
  color: var(--bs-info-tint-20);
  background-color: rgba(var(--bs-info-rgb), 0.2);
  border-color: transparent;
}

.dark .btn-link {
  color: var(--bs-primary);
}

.dark .bd-aside .active {
  color: var(--bs-primary);
  background-color: var(--bs-primary-tint-88);
}

.dark .bd-aside .btn:hover {
  color: var(--bs-primary);
  background-color: var(--bs-primary-tint-88);
}

.dark .bd-aside .btn:focus {
  color: var(--bs-primary);
  background-color: var(--bs-primary-tint-88);
  -webkit-box-shadow: 0 0 0 0.063rem rgba(var(--bs-primary-rgb), 0.7);
  box-shadow: 0 0 0 0.063rem rgba(var(--bs-primary-rgb), 0.7);
}

.dark .bd-aside a:hover {
  background-color: var(--bs-primary-tint-88);
}

.dark .btn-border.active {
  border-color: var(--bs-primary);
}

.dark .list-group-item-primary {
  color: var(--bs-primary-shade-40);
  background-color: var(--bs-primary-tint-80);
}

.dark .list-group-item-primary.list-group-item-action:hover,
.dark .list-group-item-primary.list-group-item-action:focus {
  color: var(--bs-primary-shade-40);
  background-color: var(--bs-primary-tint-60);
}

.dark .list-group-item.active {
  color: var(--bs-white);
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.dark .list-group-item-action:not(.active):hover,
.dark .list-group-item-action:not(.active):focus {
  background-color: var(--bg-body);
}

.dark .list-group-item-action:not(.active):active {
  background-color: var(--bs-primary-tint-80);
}

.dark .form-control {
  font-size: var(--body-font-size);
}

.dark .form-control:focus {
  border-color: var(--bs-primary-tint-40);
}

.dark .form-select:focus {
  border-color: var(--bs-primary-tint-40);
}

.dark .form-check-input {
  border-color: var(--bs-primary-shade-20);
}

.dark .form-check-input:checked {
  border-color: var(--bs-primary-shade-20);
}

.dark .form-check-input:focus {
  border-color: var(--bs-primary-shade-20);
}

.dark .border-primary {
  border-color: var(--bs-primary) !important;
}

.dark #top-tab-list li a {
  color: var(--bs-primary);
  background: var(--bs-primary-tint-80);
}

.dark #top-tab-list li a .iq-icon {
  background: var(--bs-primary);
}

.dark #top-tab-list li.active a {
  background: var(--bs-primary);
}

.dark #top-tab-list li.active a .iq-icon {
  color: var(--bs-primary);
}

.dark .nav-link {
  color: var(--bs-primary);
}

.dark .nav-link:hover,
.dark .nav-link:focus {
  color: var(--bs-primary-shade-20);
}

.dark .nav-tabs .nav-link.active {
  color: var(--bs-white);
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-primary-rgb), 0.3);
  box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-primary-rgb), 0.3);
}

.dark .nav-pills .nav-link.active {
  color: #fff;
  background-color: var(--bs-primary);
}

.dark .nav .search-input.input-group:focus-within .input-group-text,
.dark .nav .search-input.input-group:focus-within .form-control {
  border-color: var(--bs-primary-tint-40);
}

.dark .nav .sidebar-toggle {
  background: var(--bs-primary);
}

.dark .iq-navbar-header.navs-bg-color .iq-header-img {
  background-color: var(--bs-primary);
}

.dark .iq-navbar .dropdown .dropdown-menu.sub-drop .iq-sub-card:hover {
  background: var(--bs-primary-tint-88);
}

.dark .page-item.active .page-link {
  color: var(--bs-white);
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.dark .page-item .page-link {
  border-color: var(--bs-primary-tint-80);
  color: var(--bs-primary);
}

.dark .table-primary {
  --bs-table-bg: var(--bs-primary-tint-80);
  border-color: var(--bs-primary-tint-80);
}

.dark .type {
  background: var(--bs-primary);
}

.dark .prc-box {
  background: var(--bs-primary-tint-80);
}

.dark .prc-box.active {
  background: var(--bs-primary);
}

.dark .prc-box.active .type {
  background: var(--bs-primary-tint-80);
}

.dark .circle-progress-primary svg .circle-progress-value {
  stroke: var(--bs-primary);
}

.dark .circle-progress-primary svg .circle-progress-text {
  fill: var(--bs-primary);
}

.dark .circle-progress-info svg .circle-progress-value {
  stroke: var(--bs-info);
}

.dark .circle-progress-info svg .circle-progress-text {
  fill: var(--bs-info);
}

.dark .sidebar .sidebar-toggle {
  background: var(--bs-primary);
}

.dark .sidebar .navbar-brand svg {
  color: var(--bs-primary);
}

.dark .sidebar.sidebar-color .navbar-brand svg {
  color: #fff;
}

.dark .sidebar-default .navbar-nav .nav-item .nav-link:not(.disabled).active,
.dark
  .sidebar-default
  .navbar-nav
  .nav-item
  .nav-link:not(.disabled)[aria-expanded="true"] {
  background-color: var(--bs-primary);
  -webkit-box-shadow: 0 10px 20px -10px rgba(var(--bs-primary-rgb), 0.4);
  box-shadow: 0 10px 20px -10px rgba(var(--bs-primary-rgb), 0.4);
}

.dark
  .sidebar-default
  .navbar-nav
  .nav-item
  .nav-link:not(.disabled):hover:not(.active):not([aria-expanded="true"]) {
  background-color: var(--bs-primary-tint-88);
  color: var(--bs-primary);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dark .sidebar-color {
  background-color: var(--bs-primary) !important;
}

.dark .sidebar-color .navbar-nav .nav-item .nav-link:not(.disabled).active {
  background-color: #fff;
  color: var(--bs-primary) !important;
}

.dark
  .sidebar-color
  .navbar-nav
  .nav-item
  .nav-link:not(.disabled)[aria-expanded="true"] {
  background-color: #fff;
  color: var(--bs-primary) !important;
}

.dark
  .sidebar-color
  .navbar-nav
  .nav-item
  .nav-link:not(.disabled):hover:not(.active):not([aria-expanded="true"]) {
  background-color: rgba(var(--bs-white-rgb), 0.1);
  color: #fff;
}

.dark .sidebar-color .sidebar-header {
  border-bottom-color: var(--bs-primary-tint-20);
}

.dark .dual-horizontal {
  background: var(--bs-primary);
}

.dark .dual-horizontal .nav {
  background: var(--bs-primary);
}

.dark .boxed {
  background: var(--bs-primary);
}

.dark .boxed-fancy::before {
  background: var(--bs-primary);
}

.dark
  .sidebar-default
  .navbar-nav
  .nav-item
  .nav-link:not(.disabled):hover:not(.active):not([aria-expanded="true"]) {
  background-color: rgba(var(--bs-primary-rgb), 0.2);
}

.dark .fc-button-primary {
  color: #fff !important;
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

.text-primary {
  color: var(--bs-primary) !important;
}

.text-secondary {
  color: var(--bs-secondary) !important;
}

.bg-primary {
  background-color: var(--bs-primary) !important;
}

.bg-soft-primary {
  color: var(--bs-primary);
  background-color: rgba(var(--bs-primary-rgb), 0.1) !important;
}

.text-info {
  color: var(--bs-info) !important;
}

.bg-info {
  background-color: var(--bs-info) !important;
}

.bg-soft-info {
  color: var(--bs-info);
  background-color: rgba(var(--bs-info-rgb), 0.1) !important;
}

.bg-warning-15 {
  background-color: #cbd1efed;
}

.bg-blue-15 {
  background-color: #88667c;
}

.bg-purple-15 {
  background-color: #cbd1efed;
}

.bg-info-15 {
  background-color: #cbd1efed;
}

.fill-primary {
  fill: var(--bs-primary);
}

::selection {
  background-color: var(--bs-primary);
  color: #fff;
}

::-webkit-scrollbar {
  width: 5px;
  height: 6px;
  transition: width 0.25s ease-in-out 0s;
}

::-webkit-scrollbar-thumb {
  background: var(--bs-primary-shade-40);
  border-radius: 0.325rem;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

.table-responsive::-webkit-scrollbar-thumb {
  background: var(--bs-secondary);
}

.my-scroll {
  max-height: 33vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);
  -webkit-background-clip: text;
  transition: background-color 0.8s;
}

.my-scroll:hover {
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.18);
  transition: background-color 0.8s;
}

.my-scroll::-webkit-scrollbar-thumb {
  background-color: inherit;
}


/* ahmad-css */

.bg-light1 {
  background-color: var(--bg-bg-light1) !important;
  color: black;
  box-shadow: 0px 5px 21px -5px #cdd1e1;
  /* background-image: url(../images/bg-dot.png), url(../images/bg-dot.png), url(../images/bg-round.png), url(../images/bg-tree.png), url(../images/bg-bottom-hero.png) !important; */
  background-position: 10px 10px, bottom 190px right 10px, left 55% top -1px,
    left 45% bottom -1px, center bottom -1px;
  background-repeat: no-repeat;
  height: 240px !important;
  box-shadow: none !important;
  border-radius: 0 0 1rem 1rem !important;
}

.small1 {
  font-size: 12px;
}

.bg-blue {
  background-color: var(--bs-indigo);
}

.bg-cyan {
  background-color: var(--bs-cyan);
}

.li-hover:hover {
  background-color: var(--bs-primary-tint-88);
  color: var(--bs-primary);
  -webkit-box-shadow: none;
  cursor: pointer;
  box-shadow: none;
  transition: all 300ms ease-in-out;
}

.w-space {
  white-space: nowrap;
}

.x-scroll {
  overflow-x: scroll;
}

.summernote .ql-editor {
  min-height: 12.75rem;
}

html:not([dir="rtl"]) .modal-simple .btn-close {
  right: -2rem;
}

html:not([dir="rtl"]) .modal .btn-close {
  transform: translate(23px, -25px);
}

.modal-simple .btn-close {
  position: absolute;
  top: -2rem;
}

.modal .btn-close {
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba (161, 172, 184, 0.4);
  opacity: 1;
  padding: 0.635rem;
  transition: all 0.23s ease 0.1s;
}

.btn-close {
  background: #fff url("../images/close.png") center/1em auto no-repeat !important;
}

html:not([dir="rtl"]) .modal .btn-close:active,
html:not([dir="rtl"]) .modal .btn-close:focus,
html:not([dir="rtl"]) .modal .btn-close:hover {
  transform: translate(20px, -20px);
}

.rbt-token .btn-close {
  transform: none !important;
  border-radius: inherit;
  border: 1px solid var(--bs-primary);
}

.card-bg {
  background-color: var(--card-bg);
}

.card {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
}

.btn-danger1 {
  background-color: var(--btn-danger1);
}

.text-shadow-1 {
  text-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 25%);
}

.cursor-pointer {
  cursor: pointer;
}

.circle-progress-value {
  stroke: var(--bs-secondary);
}

/* only notification css */
.btn-play {
  position: relative;
}

.btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  display: block;
  width: 30px;
  height: 30px;
  background: var(--bs-primary);
  border-radius: 100%;
  animation: pulse-border 1500ms ease-out infinite;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.4);
    opacity: 0;
  }
}

/* only 404 css */
.gradient {
  background: radial-gradient(
    20% 60% at 50% 50%,
    var(--bs-primary) 0%,
    var(--bs-secondary) 100%
  ) !important;
}

.auth:before {
  width: 170px;
  height: 190px;
  content: " ";
  position: absolute;
  top: -60px;
  right: -50px;
  z-index: -1;
  background-image: radial-gradient(#be82a073 10%, transparent 10%),
    radial-gradient(#d94f6661 10%, transparent 10%);
  background-size: 30px 30px;
  background-position: 0 0, 30px 30px;
  background-repeat: repeat;
}

.auth:after {
  width: 170px;
  height: 190px;
  content: " ";
  position: absolute;
  bottom: -70px;
  left: -60px;
  z-index: -1;
  background-image: radial-gradient(#d94f6661 10%, transparent 10%),
    radial-gradient(#be82a073 10%, transparent 10%);
  background-size: 30px 30px;
  background-position: 0 0, 30px 30px;
  background-repeat: repeat;
}

/* only circle animation css */
.ani-icon {
  position: relative;
}

.ani-icon .circles-box {
  position: absolute;
  display: none;
  left: 0;
  top: -10px;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  animation: rotateme 30s infinite linear;
}

.ani-icon .circles-box .circle-one {
  position: absolute;
  left: 8px;
  bottom: 8px;
  width: 12px;
  height: 12px;
  z-index: 1;
  background: linear-gradient(
    90deg,
    #44a1fd 0%,
    #fff 60%,
    #fff 72%,
    #c10404 97%
  );
  border-radius: 50%;
}

.ani-icon .circles-box .circle-two {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 12px;
  background: linear-gradient(
    90deg,
    #44a1fd 0%,
    #fff 60%,
    #fff 72%,
    #c10404 97%
  );
  height: 12px;
  z-index: 1;
  border-radius: 50%;
}

@keyframes rotateme {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* only nav css */
.nav-h {
  height: 35px;
  width: 35px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  /* background: var(--bs-primary); */
  /* box-shadow: var(--btn-shadow); */
  border-radius: 0.5rem;
}

.iq-banner {
  margin-bottom: 5rem;
}

.iq-banner2 {
  margin-top: -3.9rem !important;
}

.main-content .content-inner {
  min-height: auto !important;
}

/* input shake animation */
.was-validated .form-control:invalid,
.form-control.is-invalid {
  animation: shake 0.2s ease-in-out 0s 2;
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }

  25% {
    margin-left: 0.5rem;
  }

  75% {
    margin-left: -0.5rem;
  }

  100% {
    margin-left: 0rem;
  }
}

/* only spinner css */
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* only full-screen css */
.fullscreen .exit-fullscreen {
  display: block !important;
}

.exit-fullscreen {
  display: none;
}

.fullscreen .fullscreen {
  display: none;
}

/* only sidebar css */
.sidebar-mini .default-img {
  display: none;
}

.mini-img {
  display: none;
}

.sidebar-mini .mini-img {
  display: block;
}

.nav-link.disabled,
.sidebar-default .navbar-nav .nav-item .nav-link:not(.disabled) {
  /* color: var(--bs-secondary); */
}

/* #sidebar-menu .sub-nav::before {
    content: '';
    height: 100%;
    opacity: 1;
    width: 3px;
    background: var(--bs-primary);
    position: absolute;
    left: 21%;
    top: 0;
    border-radius: 15px;
    z-index: -1;
} */
.sidebar .sidebar-body {
  overflow: auto !important;
}

.sidebar-mini .sidebar-body:hover {
  overflow: visible !important;
}

.sidebar-mini .navbar-nav .nav-item .nav-link:not(.disabled) .right-icon {
  display: none !important;
}

/* only datatable css */
.eKwPae {
  border-radius: 1rem !important;
  box-shadow: 0 0 1em 0px #7347617a !important;
  background-color: #d33333 !important;
  height: 25px !important;
  border: 0.19em solid white !important;
  width: 25px !important;
}

.ejwJAK {
  justify-content: center;
}

.eKwPae[aria-label="Expand Row"] {
  background-color: #31b131 !important;
}

.eKwPae svg {
  fill: var(--bs-white);
  transform: scale(2.5);
  height: 15px;
}

table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > td.dtr-control:before {
  background-color: var(--bs-primary) !important;
}

table.dataTable > tbody > tr.child span.dtr-title {
  color: var(--bs-primary-shade-80) !important;
}

table.dataTable > tbody > tr.child ul.dtr-details > li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

table.dataTable,
.table thead tr th {
  font-size: 0.88rem;
  text-transform: capitalize;
}

div.dataTables_wrapper div.dataTables_filter {
  display: none;
}

/* div.dataTables_wrapper div.dataTables_filter label{
    position: absolute;
    top: 6%;
    right: 23%;
}

div.dataTables_wrapper div.dataTables_filter input{
    padding: 0.4rem 1rem;
} */

/* only header css */
.shape2 {
  position: absolute;
  left: 4%;
  top: 90%;
}

.shape2 img {
  animation: movebounce 5s linear infinite;
}

@keyframes movebounce {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0px);
  }
}

/* -------shape-1-------- */
.shape1 {
  position: absolute;
  right: 20%;
  top: 38%;
}

.shape1 img {
  animation: animationFramesOne 20s infinite linear;
}

@keyframes animationFramesOne {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(20px);
  }

  100% {
    transform: translateX(0px);
  }
}

/* -------shape-3-------- */
.shape3 {
  position: absolute;
  /* top: 24%; */
}

.shp1 {
  left: 25%;
}

.shp2 {
  right: 35%;
}

.shp3 {
  right: 2%;
}

.shape3 img {
  animation: moveleftbounce 5s linear infinite;
}

@keyframes moveleftbounce {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(20px);
  }

  100% {
    transform: translateX(0px);
  }
}

/* ------shape-4--------- */
.shape7 {
  position: absolute;
  right: 29%;
  top: 30%;
}

.shape7 img {
  animation: rotateme 20s infinite linear;
}

@keyframes rotateme {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* datatable css only */
.bnAwAJ {
  min-width: auto !important;
}

/* printinvoice css only */
.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  background-color: var(--bs-white);
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.invoice-box.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
    sans-serif;
}

.invoice-box.rtl table {
  text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
  text-align: left;
}

/* pro sidebar css only */
.pro-sidebar > .pro-sidebar-inner {
  background-color: var(--bs-white) !important;
}

/* only authentication css */

.myform .form-floating > label {
  padding: 0.5rem 1rem;
  font-size: 14px;
}

.myform .form-floating > .form-control,
.form-floating > .form-select {
  height: calc(2.5rem + 2px);
}

.form-bg {
  background-image: url(https://images.pexels.com/photos/1922633/pexels-photo-1922633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
  background-position: bottom;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.form-bg .carousel-inner {
  overflow: visible !important;
}

.form-bg img {
  position: relative;
  top: -50px;
  height: 200px;
  filter: drop-shadow(1px 5px 2px white);
  width: 200px;
}

.form-bg .first-img {
  left: -20%;
  top: -100px;
  width: 100%;
  height: 100%;
}

.form-bg .first-img2 {
  width: 50%;
  right: -25%;
}

.form-bg .carousel-caption {
  bottom: -150px;
}

.form-bg .my-bottom {
  bottom: -50px;
}

.form-bg .carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
}

.form-bg .carousel-indicators {
  justify-content: end;
  top: 5%;
}

.bg-transparent1 {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

/* Profile Css only */
.card .card-body .iq-media-group-1 .iq-media-1:first-child {
  margin-left: 0;
}

.card .card-body .iq-media-group-1 .iq-media-1 {
  margin-left: -1.25rem;
  position: relative;
  z-index: 0;
}

.card .card-body .iq-media-1 {
  display: inline-flex;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.25rem;
}

.card .card-body .iq-media-group-1 .iq-media-1:hover {
  z-index: 9;
}

/* Only Button css */
.ma-btn-primary {
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.ma-btn-primary::before,
.ma-btn-primary::after {
  content: "";
  position: absolute;
  width: 30px;
  left: 0;
  top: -50%;
  height: 200%;
  background-color: #f0f8ff52;
  transform: rotate(-15deg) translateX(-150%);
  z-index: -1;
  transition: all 0.5s ease-in-out 0s;
}

.ma-btn-primary::after {
  transition-delay: 0.075s;
}

.ma-btn-primary:hover::before,
.ma-btn-primary:hover::after {
  left: 150%;
  transform: rotate(-15deg) translateX(100%);
}

.myOffcanvas.offcanvas-end {
  width: 610px;
}

.myOffcanvas .ql-container {
  height: 120px !important;
}

.rbt-token .rbt-token-remove-button .rbt-close-content {
  display: none !important;
}

.rbt-input-multi.focus {
  box-shadow: none !important;
  border-color: var(--bs-primary) !important;
}

.rbt-token {
  background-color: var(--bs-primary-shade-40) !important;
  color: var(--bs-white) !important;
}

/* OTP Css Only */
.otpContainer input {
  padding: 9px 0;
  border: 1px solid #d4d5d9;
  font-size: 14px;
  text-align: center;
  width: 30px;
  border-radius: 2px;
}

input {
  caret-color: #ff2459;
}

/* rbt-token Css Only */
.rbt-token {
  align-items: center;
  margin: 1px 7px 2px 0;
}

.rbt-token .rbt-token-remove-button {
  padding: 0.2rem 0.2rem !important;
}

/* media quary */
@media (max-width: 991.98px) {
  html,
  body {
    overflow-x: hidden;
  }

  .d-block-offcanvas {
    display: block;
  }

  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 80%;
    overflow: hidden scroll;
    padding: 1rem;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }

  .offcanvas-sm.offcanvas-sm-start {
    top: 0;
    left: 0;
    width: 400px;
    transform: translateX(-100%);
    background-color: white;
  }

  .offcanvas-sm.show:not(.hiding),
  .offcanvas-sm.showing {
    transform: none;
  }

  .footer {
    left: auto !important;
  }
}
@media screen and (min-width: 768px) {
  .sidebar-mini .sidebar-list .navbar-nav .nav-item .nav-link:hover span {
    transition: all 400ms ease;
    transform: none !important;
    opacity: 1 !important;
    background: #000;
    color: white;
    border-radius: 0.3rem;
    padding: 0 5px;
    border: 0;
    position: relative;
  }
}

/* media quary */

/* ahmad-css */
